* {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
  background-color: whitesmoke;
}

.contacts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

.contact-card {
  flex-basis: 225px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 13px;
  padding-bottom: 20px;
  background-color: white;
}

.contact-card > img {
  width: 100%;
  height: auto;
  align-self: center;
  border-radius: 5px;
  object-fit: cover;
}

.contact-card > h3 {
  font-weight: 700;
  font-size: 18px;
}

.info-group {
  display: flex;
  align-items: center;
}

.info-group > img {
  height: 11px;
  margin-right: 8px;
}

.info-group > p {
  margin-block: 3px;
  font-size: 12px;
  color: #2B283A;
}